<template>
  <div class="container w-full mx-auto">
    <div class="bg-white mt-5 p-10 mb-4" data-testid="page-error">
      <div class="text-2xl uppercase font-black font-oswald">
        {{ $t('salesforce.heading') }}
      </div>
      <div class="text-2lg mt-3">
        {{ $t('salesforce.subHeading') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  setup() {
    return {};
  },
};
</script>
